import { useTranslation } from "react-i18next";
import { BANNER_BLAM_THEME } from "storeseo-enums/cacheKeys";
import DismissableBanner from "../common/DismissableBanner";

const BlumThemeCard = () => {
  const { t } = useTranslation();

  return (
    <DismissableBanner
      mediaCard
      title={t("Slow Website Killing Sales? Boost Conversions with Blum.")}
      content={t(
        "Enhance your website's speed and ranking! Unleash the fast and beautiful Blum theme for Shopify to create SEO-optimized online store."
      )}
      primaryAction={{
        content: t("Start Free Trial"),
        url: "https://themes.shopify.com/themes/blum/styles/mondo?utm_source=storeware&utm_medium=storeseo&utm_campaign=sht&utm_content=media-card",
        target: "_blank",
      }}
      illustration="/img/partnerships/blum-theme.png"
      bannerKey={BANNER_BLAM_THEME}
    />
  );
};

export default BlumThemeCard;
